<template>
  <nav>
    <!-- horizontal app bar -->
    <v-app-bar flat dense id="bar">
      <v-app-bar-nav-icon
        class="grey--text"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div>
        <app-bar-user-menu></app-bar-user-menu>
      </div>
    </v-app-bar>

    <!-- vertival drawer -->
    <v-navigation-drawer v-model="drawer" app id="drawer" class="drawer">
      <!-- logo -->
      <v-list-item>
        <v-list-item-content>
          <router-link to="/">
            <v-list-item-title
              class="title text-uppercase font-weight-black headline pt-2"
              >Karma 360</v-list-item-title
            >
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <!-- navigation menu -->
      <v-list nav dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="(item, i) in filteredLinks"
            :key="i"
            :to="item.to"
          >
            <!-- icon -->
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <!-- text -->
            <v-list-item-content>
              <v-list-item-title>{{
                $t(`drawer.${item.text}`)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <div class="px-4 mt-2">
            <a
              href="https://vizuro.com/contact"
              target="_blank"
              class="primary--text"
              ><img
                src="~@/assets/Vizuro Logo - PNG.webp"
                alt="logo"
                style="width: 150px"
                href="https://vizuro.com/contact"
                target="_blank"
            /></a>
            <div class="mt-2">Copyright &copy; Vizuro LLC.</div>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import AppBarUserMenu from "@/core/layouts/components/app-bar/AppBarUserMenu.vue";

export default {
  name: "Drawer",
  components: {
    AppBarUserMenu,
  },
  data: () => ({
    drawer: null,
    selectedItem: 0,
    links: [
      {
        text: "Dashboard",
        icon: "mdi-view-dashboard-outline",
        to: "/",
      },
      {
        text: "DatasetGroups",
        icon: "mdi-database-outline",
        to: "/datasetGroups",
      },
      {
        text: "Models",
        icon: "mdi-cube-outline",
        to: "/models",
      },
      {
        text: "Results",
        icon: "mdi-chart-box-outline",
        to: "/results",
      },
      // {
      //   text: "Docs",
      //   icon: "mdi-file-document-outline",
      //   to: "/docs",
      // },
      // {
      //   text: "Settings",
      //   icon: "mdi-cog",
      //   to: "/settings",
      // },
      {
        text: "Docs",
        icon: "mdi-file-document-outline",
        to: "/docs",
      },
      {
        text: "Feedback",
        icon: "mdi-message-text-outline",
        to: "/feedback",
      },
      {
        text: "Users",
        icon: "mdi-account-multiple-outline",
        to: "/users",
      },
    ],
    userData: null,
  }),
  computed: {
    filteredLinks() {
      // Assuming you have a variable or method to get the user role, replace 'isAdmin' with your actual logic.
      const isAdmin = this.isUserAdmin(); // Replace with your logic to check if the user is an admin
      return isAdmin
        ? this.links
        : this.links.filter((item) => item.text !== "Users");
    },
  },
  methods: {
    // Replace this method with your logic to check if the user is an admin
    isUserAdmin() {
      if (this.userData.is_admin) {
        return true;
      } else {
        return false;
      }
    },
    getUser() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.userData = userData;
    },
  },
  created() {
    this.getUser();
  },
};
</script>

<style scoped>
a {
  color: unset;
  text-decoration: none;
}
</style>
