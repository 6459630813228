<template>
  <div>
    <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar v-bind="attrs" color="primary" v-on="on" size="36">
            <v-icon dark> mdi-account-circle </v-icon>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge
            bottom
            color="success"
            overlap
            offset-x="12"
            offset-y="12"
            class="ms-4"
            dot
          >
            <v-avatar color="primary" size="36">
              <v-icon dark> mdi-account-circle </v-icon>
            </v-avatar>
          </v-badge>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align: middle"
          >
            <span class="text--primary font-weight-semibold mb-n1">
              {{ userData.name }}
            </span>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Profile -->
        <v-list-item @click="onStartProfileDialog">
          <v-list-item-icon class="me-2">
            <v-icon size="22"> mdi-account </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item @click="logoutUser">
          <v-list-item-icon class="me-2">
            <v-icon size="22"> mdi-logout </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Update user info -->
    <v-dialog v-model="dialogProfile" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">USER INFORMATION</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form class="mt-5">
                  <v-text-field
                    v-model="profile.email"
                    label="Email (Read only)"
                    dense
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-model="profile.username"
                    label="User name"
                    :error-messages="profileUsernameErrors"
                    required
                    @change="$v.profile.username.$touch()"
                    @blur="$v.profile.username.$touch()"
                    dense
                  ></v-text-field>
                </form>
              </v-col>
              <v-col class="text-right pt-0">
                <v-btn text color="primary" @click="onClickPass">
                  <span>Change Password</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseProfileDialog">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onEditProfile"
            :disabled="onUpdateBtnLocked"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Update pass info -->
    <v-dialog v-model="dialogPass" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">CHANGE PASSWORD</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <form class="mt-5">
                  <v-text-field
                    v-model="pass.currPass"
                    :append-icon="showCurrPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showCurrPass ? 'text' : 'password'"
                    label="Current password"
                    :error-messages="passCurrPassErrors"
                    required
                    @change="$v.pass.currPass.$touch()"
                    @blur="$v.pass.currPass.$touch()"
                    dense
                    @click:append="showCurrPass = !showCurrPass"
                    autocomplete="current-password"
                  ></v-text-field>
                  <v-text-field
                    v-model="pass.newPass"
                    :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showNewPass ? 'text' : 'password'"
                    label="New password"
                    :error-messages="passNewPassErrors"
                    required
                    @change="$v.pass.newPass.$touch()"
                    @blur="$v.pass.newPass.$touch()"
                    dense
                    @click:append="showNewPass = !showNewPass"
                  ></v-text-field>
                </form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClosePassDialog">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onEditPass"
            :disabled="onUpdatePassBtnLocked"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import data from "@/models/data";

const passwordValidator = (value) => {
  if (!value) {
    return true; // Let required validator handle empty case
  }
  // Custom validation rule for password that allows only specific characters
  const allowedCharacters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_~!@#$%^&*()+-=:;<>,./?";

  const regex = new RegExp(`^[${allowedCharacters}]+$`);

  return regex.test(value);
};

export default {
  data: () => ({
    userData: null,
    dialogProfile: null,
    onUpdateBtnLocked: false,
    onUpdatePassBtnLocked: false,
    profile: {
      email: null,
      username: null,
    },
    pass: {
      currPass: null,
      newPass: null,
    },
    dialogPass: null,
    showCurrPass: false,
    showNewPass: false,
  }),
  validations: {
    profile: {
      username: {
        required,
        maxLength: maxLength(32),
      },
    },
    pass: {
      currPass: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(64),
        passwordValidator,
      },
      newPass: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(64),
        passwordValidator,
      },
    },
  },
  computed: {
    profileUsernameErrors() {
      const errors = [];
      if (!this.$v.profile.username.$dirty) return errors;
      !this.$v.profile.username.required && errors.push(this.$t("required"));
      console.log(this.$v.profile.username.$params);
      !this.$v.profile.username.maxLength &&
        errors.push(
          `Must be at most ${this.$v.profile.username.$params.maxLength.max}`
        );
      return errors;
    },
    passCurrPassErrors() {
      const errors = [];
      const currPassValidation = this.$v.pass.currPass;

      if (!currPassValidation.$dirty) return errors;

      if (!currPassValidation.required) {
        errors.push(this.$t("required"));
      }

      if (!currPassValidation.minLength) {
        errors.push(
          `Must be at least ${currPassValidation.$params.minLength.min} characters`
        );
      }

      if (!currPassValidation.maxLength) {
        errors.push(
          `Must be at most ${currPassValidation.$params.maxLength.max} characters`
        );
      }
      if (!currPassValidation.passwordValidator) {
        errors.push(
          `Choose character within the list "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_~!@#$%^&*()+-=:;<>,./?"`
        );
      }

      return errors;
    },
    passNewPassErrors() {
      const errors = [];
      const newPassValidation = this.$v.pass.newPass;

      if (!newPassValidation.$dirty) return errors;

      if (!newPassValidation.required) {
        errors.push(this.$t("required"));
      }

      if (!newPassValidation.minLength) {
        errors.push(
          `Must be at least ${newPassValidation.$params.minLength.min} characters`
        );
      }

      if (!newPassValidation.maxLength) {
        errors.push(
          `Must be at most ${newPassValidation.$params.maxLength.max} characters`
        );
      }
      if (!newPassValidation.passwordValidator) {
        errors.push(
          `Choose character within the list "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_~!@#$%^&*()+-=:;<>,./?"`
        );
      }

      return errors;
    },
  },
  methods: {
    async logoutUser() {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");

      // Remove userData & Ability from localStorage
      localStorage.removeItem("userData");

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
    onStartProfileDialog() {
      this.dialogProfile = true;
    },
    onCloseProfileDialog() {
      this.dialogProfile = false;
    },
    async onEditProfile() {
      this.onUpdateBtnLocked = true;
      this.$v.profile.$touch();
      if (this.$v.profile.$invalid) {
        // valid
        console.log("this.$v.$invalid", this.$v.$invalid);
        if (this.$v.profile.$invalid) {
          console.log("this.$v.profile", this.$v.profile.$invalid);
        }
      } else {
        const payload = {
          username: this.profile.username,
        };
        try {
          const res = await data.updateUserProfile(payload);
          if (res.status !== 200) {
            this.$dialog.message.warning("Upadate profile error.", {
              position: "top",
            });
          } else if (res.status === 200) {
            this.onCloseProfileDialog();
            this.$dialog.message.success("Upadate profile successfully.", {
              position: "top",
            });
            let response = await data.userProfile();
            localStorage.setItem("userData", JSON.stringify(response));
            const userData = JSON.parse(localStorage.getItem("userData"));
            this.userData = userData;
          } else {
            this.$dialog.message.error(this.$t("systemError"), {
              position: "top",
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
      this.onUpdateBtnLocked = false;
    },
    onClickPass() {
      // Close the profile dialog
      this.onCloseProfileDialog();

      // Open the pass dialog
      this.dialogPass = true;
    },
    onClosePassDialog() {
      this.dialogPass = false;
      this.pass.currPass = null;
      this.pass.newPass = null;
    },
    async onEditPass() {
      this.onUpdatePassBtnLocked = true;
      this.$v.pass.$touch();
      if (this.$v.pass.$pending || this.$v.pass.$invalid) {
        // valid
        console.log("this.$v.$invalid", this.$v.$invalid);
        if (this.$v.pass.$invalid) {
          console.log("this.$v.pass", this.$v.pass.$invalid);
        }
      } else {
        const payload = {
          old_password: this.pass.currPass,
          new_password: this.pass.newPass,
        };
        try {
          const res = await data.updateUserPass(payload);
          if (res.status !== 200) {
            this.$dialog.message.warning("Upadate password error.", {
              position: "top",
            });
          } else if (res.status === 200) {
            this.onClosePassDialog();
            this.$dialog.message.success("Upadate password successfully.", {
              position: "top",
            });
          } else {
            this.$dialog.message.error(this.$t("systemError"), {
              position: "top",
            });
          }
        } catch (error) {
          if (error.response.status === 401) {
            this.$dialog.message.warning("Current password is incorrect", {
              position: "top",
            });
          }
          console.error(error);
        }
      }
      this.onUpdatePassBtnLocked = false;
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      console.log("userData");
      console.log(userData);
      this.userData = userData;
      this.profile.email = this.userData.email;
      this.profile.username = this.userData.name;
    } else {
      console.log("userData: null");
      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    }
  },
};
</script>

<style lang="scss"></style>
